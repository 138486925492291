@font-face {
	font-family: "HindSiliguriLight";
	src: local("HindSiliguriLight"),
		url("./fonts/HindSiliguri/HindSiliguri-Light.ttf") format("truetype");
	font-weight: 300;
}

@font-face {
	font-family: "HindSiliguri";
	src: local("HindSiliguri"), url("./fonts/HindSiliguri/HindSiliguri-Medium.ttf") format("truetype");
	font-weight: 500;
}

@font-face {
	font-family: "HindSiliguriBold";
	src: local("HindSiliguriBold"),
		url("./fonts/HindSiliguri/HindSiliguri-Bold.ttf") format("truetype");
	font-weight: 700;
}

body {
	margin: 0;
	font-family: "HindSiliguri", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
