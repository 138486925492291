@import "../../App/App.scss";

.add-event-icon {
	color: lighten($color: $grey, $amount: 25);
	width: 1.4rem;
	height: auto;
}

.monthContainer {
	display: grid;
	grid-template-columns: repeat(7, 1fr);

	.day {
		height: 25px;
		width: 25px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		font-weight: 600;
		@include fluid-type(1rem, 1.05rem);
		margin: 10px 0 5px 5px;
		color: $secondary-color;
	}

	.day:hover {
		background-color: $secondary-color;
		color: white;
	}

	.inactiveDay {
		color: $grey;
		font-weight: 400;
	}

	.inactiveDay:hover {
		background-color: $secondary-color;
		color: white;
	}

	.today {
		background: #efefee;
		border-radius: 50%;
	}

	.today:hover {
		background-color: $secondary-color;
		color: white;
	}

	.selectedDay {
		color: white;
		background: $primary-color;
		border-radius: 50%;
	}

	.weekNames {
		color: $grey;
		margin: 25px 0 10px 5px;
		text-transform: uppercase;
		font-size: 0.85rem;
		text-align: center;
	}
}

.weekContainer {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	margin-top: 1rem;

	.weekNames {
		color: lightslategrey;
		margin: 0 5px;
		text-align: center;
		text-transform: uppercase;
		font-size: 0.85rem;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		border-bottom: solid 1px $light-grey;
		border-top: solid 1px $light-grey;
		padding: 0.5rem 0;
	}

	.weekview-day {
		height: 25px;
		width: 25px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		font-weight: 600;
		font-size: 0.95rem;
		margin: 10px 0 5px 5px;
		color: #00a0e3;
		font-size: 1.4rem;
		padding: 0.2rem;
	}

	.weekview-day:hover {
		background-color: #00a0e3;
		color: white;
	}
}

.navIcon {
	width: 50px;
	height: 50px;
	padding: 8px;
	cursor: pointer;
}

.navIcon:hover {
	border-radius: 50%;
	background: #efefee;
}

.month-card {
	border-top: solid 1px #9e9e9e;
	border-left: solid 1px #9e9e9e;
	width: 100%;
	height: 100%;
	min-height: 18vh;
	padding: 0;
	margin: 0;
}

.month-card:nth-child(0n + 1) {
	border-left: none;
}

.month-card:nth-child(0n + 8) {
	border-left: none;
}

.month-card:nth-child(0n + 15) {
	border-left: none;
}

.month-card:nth-child(0n + 22) {
	border-left: none;
}

.month-card:nth-child(0n + 29) {
	border-left: none;
}

.month-card:nth-child(0n + 36) {
	border-left: none;
}

.day-card {
	// border-right: solid 1px #9e9e9e;
	width: 100%;
	height: 100%;
	min-height: 50vh;
	padding: 0;
	margin: 0 0.1rem 0.3rem 0.1rem;
}

.calenday-container {
	overflow-y: scroll;
	overflow-x: hidden;
	padding: 0;
	margin: 0;
	height: 100%;
	max-height: 70vh;
}

.calenday-hours {
	margin: 0 0 0.2rem 0.2rem;
	width: 3.5rem;
	p {
		font-size: 0.77rem;
		font-weight: 400;
		color: $grey;
	}
}

.calenday-events {
	width: 100%;
	padding: 0 1rem 0 0;
}

.calendar-card {
	padding: 0.3rem 0.1rem 0.3rem 0.3rem;
	margin: 0 0 0.5rem 0;

	&.visio {
		border-left: solid 3px $contrast-color;
		background-color: lighten($color: $contrast-color, $amount: 32);
		.type {
			text-transform: uppercase;
			@include fluid-type(0.75rem, 0.8rem);
			color: $contrast-color;
			font-weight: 600;
			letter-spacing: 0.5px;
			margin: 0;
			padding: 0;
		}
		.info {
			margin: 0.25rem 0;
		}
	}
	&.event {
		border-left: solid 3px $green;
		background-color: lighten($color: #50c878, $amount: 41);

		.type {
			text-transform: uppercase;
			@include fluid-type(0.75rem, 0.8rem);
			color: $green;
			font-weight: 600;
			letter-spacing: 0.5px;
			margin: 0;
			padding: 0;
		}
		.info {
			margin: 0.25rem 0;
		}
	}
	&.workshop {
		border-left: solid 3px $secondary-color;
		background-color: lighten($color: $secondary-color, $amount: 50);

		.type {
			text-transform: uppercase;
			@include fluid-type(0.75rem, 0.8rem);
			color: $secondary-color;
			font-weight: 600;
			letter-spacing: 0.5px;
			margin: 0;
			padding: 0;
		}
		.info {
			margin: 0.25rem 0;
		}
	}
}

.calendar-card:hover {
	&.event {
		background-color: lighten($color: #50c878, $amount: 8);

		.type {
			color: $white !important;
		}
		.info {
			color: $white;
		}
	}
	&.workshop {
		border-left: solid 4px $secondary-color;
		background-color: lighten($color: $secondary-color, $amount: 8);

		.type {
			color: $white;
		}
		.info {
			color: $white;
		}
	}
}

.calendar-month-card {
	padding: 0.25rem 0 0.5rem 1rem;
	margin: 0 0 0.5rem 0;
	cursor: pointer;

	&.visio {
		background-color: lighten($color: $contrast-color, $amount: 32);
		.type {
			text-transform: uppercase;
			@include fluid-type(0.75rem, 0.8rem);
			color: $contrast-color;
			font-weight: 600;
			letter-spacing: 0.5px;
			margin: 0;
			padding: 0;
		}
		.info {
			margin: 0.25rem 0;
		}
	}
	&.event {
		background-color: lighten($color: #50c878, $amount: 41);

		.type {
			text-transform: uppercase;
			@include fluid-type(0.75rem, 0.8rem);
			color: $green;
			font-weight: 600;
			letter-spacing: 0.5px;
			margin: 0;
			padding: 0;
		}
		.info {
			margin: 0.25rem 0;
		}
	}
	&.workshop {
		background-color: lighten($color: $secondary-color, $amount: 50);

		.type {
			text-transform: uppercase;
			@include fluid-type(0.75rem, 0.8rem);
			color: $secondary-color;
			font-weight: 600;
			letter-spacing: 0.5px;
			margin: 0;
			padding: 0;
		}
		.info {
			margin: 0.25rem 0;
		}
	}
}
.calendar-month-card:hover {
	&.visio {
		background-color: $contrast-color;
		.type {
			color: $white;
		}
		.info {
			color: $white;
		}
	}
	&.event {
		background-color: $green;

		.type {
			color: $white !important;
		}
		.info {
			color: $white;
		}
	}
	&.workshop {
		background-color: $secondary-color;

		.type {
			color: $white;
		}
		.info {
			color: $white;
		}
	}
}

.add-event {
	color: white;
	cursor: pointer;
	@include fluid-type(0.75rem, 0.8rem);
}
.add-event:hover {
	color: $grey;
}
