@import "../../App/App.scss";

.senior-buttons {
	list-style-type: none;
	margin: 0.5rem 0 0 0;
	padding: 0;
}

.senior-buttons li {
	display: inline-block;
}

.all-seniors-button {
	padding: 0.1rem 0.3rem;
	border-radius: 7px;
	font-weight: 600;
	margin: 0 0.5rem 0.5rem 0;
	line-height: 2rem;
	background-color: $grey;
	border: solid 1px $grey;
	color: white;
	letter-spacing: 0.3px;
	.senior-buttons-icon {
		width: auto;
		height: 1.1rem;
		color: white;
		margin: 0 0.5rem 0 0.3rem;
		vertical-align: middle;
	}
}

.all-seniors-button:hover {
	background-color: $secondary-color;
	border: solid 1px $secondary-color;
}

.home-unit-button {
	padding: 0.1rem 0.3rem;
	border-radius: 7px;
	font-weight: 600;
	margin: 0 0.5rem 0.5rem 0;
	line-height: 2rem;
	background-color: $secondary-color;
	border: solid 1px $secondary-color;
	color: white;
	letter-spacing: 0.3px;
	.senior-buttons-icon {
		width: auto;
		height: 1.1rem;
		color: white;
		margin: 0 0.5rem 0 0.3rem;
		vertical-align: middle;
	}
}

.senior-buttons-del {
	padding: 0.1rem 0.3rem;
	border-radius: 7px;
	font-weight: 600;
	margin: 0 0.5rem 0.5rem 0;
	line-height: 2rem;
	background-color: $secondary-color;
	border: solid 1px $secondary-color;
	color: white;
	letter-spacing: 0.3px;
	.senior-buttons-icon {
		width: auto;
		height: 1.1rem;
		color: white;
		margin: 0 0.5rem 0 0.3rem;
		vertical-align: middle;
	}
}

.senior-buttons-add {
	padding: 0.1rem 0.3rem;
	border-radius: 7px;
	font-weight: 600;
	margin: 0 0.5rem 0.5rem 0;
	line-height: 2rem;
	background-color: white;
	border: solid 1px $grey;
	color: $grey;
	letter-spacing: 0.3px;
	.senior-buttons-icon {
		width: auto;
		height: 1.1rem;
		color: $grey;
		margin: 0 0.5rem 0 0.3rem;
		vertical-align: middle;
	}
}
.senior-buttons-add:hover {
	background-color: $grey;
	color: white;
	.senior-buttons-icon {
		color: white;
	}
}
