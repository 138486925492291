@import "../App/App.scss";

.login-container {
	width: 80%;
	border-radius: 7px;
	margin-top: 5vh;

	h1 {
		color: $primary-color;
		letter-spacing: 1px;
		@include fluid-type(1.4rem, 1.8rem);
		padding-bottom: 2rem;
		border-bottom: solid 3px $light-color;
	}
}

.input-password {
	position: relative;
}

.view-password {
	position: absolute;
	right: 1rem;
	top: 2.5rem;
}
