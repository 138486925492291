@import "../../App/App.scss";

.react-datepicker {
	background-color: #fff;
	color: $primary-color;
	border: 1px solid $primary-color;
}

.react-datepicker__header {
	background-color: $primary-color;
	border-bottom: none;
	border-top-left-radius: $primary-color;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	color: $white;
}

.react-datepicker__time-container {
	border-left: 1px solid $primary-color;

	.react-datepicker__time {
		background: white;

		.react-datepicker__time-box {
			ul.react-datepicker__time-list {
				li.react-datepicker__time-list-item {
					&:hover {
						background-color: $secondary-color;
					}

					&--selected {
						background-color: $secondary-color;
						color: white;

						&:hover {
							background-color: $secondary-color;
						}
					}
				}
			}
		}
	}
}

.react-datepicker__week-number {
	color: $primary-color;

	&.react-datepicker__week-number--clickable {
		cursor: pointer;

		&:hover {
			background-color: $secondary-color;
		}
	}
}

.react-datepicker__day-name {
	color: $white;
}
.react-datepicker__day,
.react-datepicker__time-name {
	color: $primary-color;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
	&:hover {
		background-color: darken($color: $secondary-color, $amount: 10%);
	}

	&--highlighted {
		background-color: $secondary-color;
		color: #fff;

		&:hover {
			background-color: $secondary-color;
		}

		&-custom-1 {
			color: $contrast-color;
		}

		&-custom-2 {
			color: $grey;
		}
	}

	&--selected,
	&--in-selecting-range,
	&--in-range {
		background-color: $secondary-color;
		color: #fff;

		&:hover {
			background-color: darken($color: $secondary-color, $amount: 10%);
		}
	}

	&--keyboard-selected {
		background-color: lighten($secondary-color, 10%);
		color: #fff;

		&:hover {
			background-color: darken($secondary-color, 5%);
		}
	}

	&--in-selecting-range:not(&--in-range) {
		background-color: $secondary-color;
	}

	&--in-range:not(&--in-selecting-range) {
		.react-datepicker__month--selecting-range & {
			background-color: $secondary-color;
			color: $white;
		}
	}
}

.react-datepicker__month-text,
.react-datepicker__quarter-text {
	&.react-datepicker__month--selected,
	&.react-datepicker__month--in-range,
	&.react-datepicker__quarter--selected,
	&.react-datepicker__quarter--in-range {
		&:hover {
			background-color: $secondary-color;
		}
	}

	&:hover {
		background-color: $secondary-color;
	}
}

.react-datepicker__today-button {
	background: $contrast-color;
}
